<template>
  <div class="un-found-page">
    <img class="un-found-img" src="@/static/image/404/404.png" alt="404-img" />
    <h4 class="un-found-title">Oops, This Page Could Not Be Found.</h4>
    <p class="un-found-ftitle">
      The page you are looking for might have been removed had its name changed
      or is temporarily unavailable.
    </p>
    <router-link class="un-found-btn" to="/">
      <el-button type="primary">Back to home</el-button>
    </router-link>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "UnFound",
  data() {
    return {};
  },
  computed: {
    ...mapState("bar", {
      nav: "navBarShow",
      side: "sideBarShow",
    }),
  },
  created() {
    this.backup = { nav: this.nav, side: this.side };
    // 切换side
    this.navBarShow(false);
    this.sideBarShow(false);
  },
  destroyed() {
    this.navBarShow(this.backup.nav);
    this.sideBarShow(this.backup.side);
  },
  methods: {
    ...mapMutations("bar", ["navBarShow", "sideBarShow"]),
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
